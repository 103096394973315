import React from 'react';
import H5AudioPlayer from 'react-h5-audio-player';
import {
  ChainSVG,
  ConfetisDroiteSVG,
  ConfetisGaucheSVG,
  FrigoEnchainerSVG,
  HornMP3,
  LuminaireSuspenduSVG,
  TableChaiseSVG,
} from 'services/exports-list';
import SVGLoader from 'component/svg/SVG';
import ActivityFridgeLocker from 'component/activity-fridge-locker/ActivityFridgeLocker';
import sceneInteraction from 'services/scene-interaction';
import './ActivityFridge.scss';

interface IProps {
  onFinish: (currentNumber: number) => void;
  isSuccessful?: boolean;
}

const ActivityFridge = ({ onFinish, isSuccessful = false }: IProps): React.ReactElement => {
  const isMusicEnabled = sceneInteraction.isMusicEnabled();
  const chainOpened: React.ReactNode = (
    <div className="activity-fridge__confetis-wrapper">
      <SVGLoader className="activity-fridge__confetis activity-fridge__confetis-left" src={ConfetisGaucheSVG} />
      <SVGLoader className="activity-fridge__confetis activity-fridge__confetis-right" src={ConfetisDroiteSVG} />
    </div>
  );
  return (
    <div className="activity-fridge">
      {isSuccessful && isMusicEnabled && <H5AudioPlayer src={HornMP3} autoPlay />}
      <div className="activity-fridge__freezer-wrapper">
        <SVGLoader src={FrigoEnchainerSVG} className="activity-fridge__freezer" />
        <ActivityFridgeLocker onFinish={(timeleft) => onFinish(timeleft)} />
        <div className="activity-fridge__chain-wrapper">
          {isSuccessful && <SVGLoader className="activity-fridge__chain --is-open" src={ChainSVG} />}
          {isSuccessful && chainOpened}
        </div>
      </div>
      <SVGLoader src={TableChaiseSVG} className="activity-fridge__chair-table" />
      <SVGLoader src={LuminaireSuspenduSVG} className="activity-fridge__light" />
    </div>
  );
};
export default ActivityFridge;
