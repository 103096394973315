import React from 'react';
import SplashScreen from 'component/splash-screen/SplashScreen';
import SVGLoader from 'component/svg/SVG';
import {
  BarSVG,
  BuildingSVG,
  VIPChainSVG,
  Cloud1SVG,
  Cloud2SVG,
  Cloud3SVG,
  Cloud4SVG,
  DoorManSVG,
  EnseigneSVG,
  LeftFlashingLampSVG,
  LeftPlantSVG,
  RightLampSVG,
  RightPlantSVG,
  RockSVG,
  SkySVG,
} from 'services/exports-list';
import './ScreenStart.scss';

const ScreenStart = (): React.ReactElement => {
  const backgroundSkyStyle = {
    backgroundImage: `url(${SkySVG})`,
  };

  return (
    <div className="screen-start">
      <div className="screen-start__background-sky" style={backgroundSkyStyle} />
      <SVGLoader src={BuildingSVG} className="screen-start__building-background" />
      <div className="screen-start-cloud-wrapper">
        <SVGLoader src={Cloud1SVG} className="screen-start__cloud screen-start__cloud--1" />
        <SVGLoader src={Cloud2SVG} className="screen-start__cloud screen-start__cloud--2" />
        <SVGLoader src={Cloud3SVG} className="screen-start__cloud screen-start__cloud--3" />
        <SVGLoader src={Cloud4SVG} className="screen-start__cloud screen-start__cloud--4" />
      </div>
      <div className="screen-start__road-wrapper">
        <SVGLoader src={RockSVG} className="screen-start__road-rock" />
      </div>

      <div className="screen-start__bar-wrapper">
        <SVGLoader src={BarSVG} className="screen-start__bar" />
        <SVGLoader src={EnseigneSVG} className="screen-start__bar-enseigne" />
      </div>
      <SVGLoader className="screen-start__flower screen-start__flower-left" src={LeftPlantSVG} />
      <SVGLoader className="screen-start__flower screen-start__flower-right" src={RightPlantSVG} />
      <SVGLoader className="screen-start__chain" src={VIPChainSVG} />
      <SVGLoader className="screen-start__lamp screen-start__lamp-left" src={LeftFlashingLampSVG} />
      <SVGLoader className="screen-start__lamp screen-start__lamp-right" src={RightLampSVG} />
      <SVGLoader className="screen-start__doorman" src={DoorManSVG} />

      <SplashScreen />
    </div>
  );
};
export default ScreenStart;
