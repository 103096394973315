import React, { useState } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { ECocktaiIngredientlList, ECocktailDND } from 'enum/cocktail';
import cocktailInteraction from 'services/cocktail-interaction';
import './CocktailDropZone.scss';

interface IProps {
  img: string;
  number?: number;
}

const CocktailDropZone = ({ img, number = 1 }: IProps): React.ReactElement => {
  const [ingredient, setIngredient] = useState<ECocktaiIngredientlList>();
  const [, dropZoneRef] = useDrop(() => ({
    accept: ECocktailDND.INGREDIENT,
    drop: (_item, monitor: DropTargetMonitor) => {
      const item: { id: ECocktaiIngredientlList } = monitor.getItem();
      setIngredient(item.id);
      cocktailInteraction.setSelectedIngredient(item.id, number - 1);
    },
  }));
  return (
    <div ref={dropZoneRef} className="cocktail-drop-zone">
      {ingredient && (
        <img
          alt={ingredient}
          src={cocktailInteraction.getCocktailByName(ingredient)}
          className="cocktail-drop-zone__selection"
        />
      )}
      {!ingredient && (
        <img alt={`Zone ${number} pour déposer les ingrédients`} src={img} className="cocktail-drop-zone__icon" />
      )}
    </div>
  );
};
export default CocktailDropZone;
