import React from 'react';
import content from './content.json';
import Button from '../button/Button';
import { EScene } from '../../enum/scene';
import { EButtonColor } from '../../enum/button';
import sceneInteraction from '../../services/scene-interaction';
import googleTagManager from 'services/google-tag-manager';
import SVGLoader from 'component/svg/SVG';
import {
  MachineToutousOmbreSVG,
  MachineToutousSVG,
  ToutouAnxieteSVG,
  ToutouStressSVG,
  ToutouTroublesCognitifsSVG,
  ToutouTroublesSommeilSVG,
} from 'services/exports-list';
import ToysMachineButton from 'component/toys-machine-button/ToysMachineButton';
import ToysMachineWindow from 'component/toys-machine-window/ToysMachineWindow';
import ActivitySplashScreen from 'component/activity-splash-screen/ActivitySplashScreen';
import { EConsumption } from 'enum/user';
import { EToys } from 'enum/toys';
import toysInteraction from 'services/toys-interaction';
import userInteraction from 'services/user-interaction';
import './ActivityToysMachine.scss';

interface IProps {
  onResultEvt?: () => void;
}

const ActivityToysMachine = ({ onResultEvt = () => {} }: IProps): React.ReactElement => {
  const stepNumber: number = toysInteraction.getToysInfo().stepNumberToys;
  const finalStepNumber: number = toysInteraction.getToysInfo().finalStepNumberToys;
  const { isDropped } = toysInteraction.getToysInfo();
  const { isCraneDown } = toysInteraction.getToysInfo();

  const onCraneDrop = (): void => {
    toysInteraction.setDropState(true);
    toysInteraction.setCraneIsDown(true);
    const selectedStep = stepNumber;
    window?.setTimeout((): void => {
      toysInteraction.setCraneIsDown(false);
      window?.setTimeout((): void => {
        toysInteraction.setStepNumberToys(5);
        window?.setTimeout((): void => {
          toysInteraction.setFinalStepNumberToys(selectedStep);
          onResultEvt();
        }, 1250);
      }, 750);
    }, 750);
  };

  window.addEventListener('resetActivity', (): void => {
    toysInteraction.setDropState(false);
    toysInteraction.setStepNumberToys(3);
    toysInteraction.setFinalStepNumberToys(0);
  });

  const goToPrevStep = (): void => {
    googleTagManager.onRestartButtonClick(EScene.TOYS_MACHINE);
    toysInteraction.setDropState(false);
    toysInteraction.setStepNumberToys(3);
    toysInteraction.setFinalStepNumberToys(0);
  };

  const goToBarScene = (): void => {
    googleTagManager.onQuitButtonClick(EScene.TOYS_MACHINE);
    sceneInteraction.goToBarScreen(true);
    setTimeout((): void => {
      toysInteraction.setDropState(false);
      toysInteraction.setStepNumberToys(3);
      toysInteraction.setFinalStepNumberToys(0);
    }, 500);
  };

  if (finalStepNumber) {
    const selectedToys: EToys | null = Object.entries(EToys)[finalStepNumber - 1][1];

    if (selectedToys) {
      type ObjectKey = keyof typeof responses;
      type Response = {
        type: keyof typeof imageToys;
        title: string;
        description: string;
      };

      const userConsumptionType: EConsumption = userInteraction.getUserConsumption();
      const responses: object = userConsumptionType === EConsumption.VAPER ? content.vaper : content.smoker;
      const toy = selectedToys?.toLowerCase() as ObjectKey;
      const { type, title, description }: Response = responses[toy];
      const imageToys: object = {
        Anxiete: ToutouAnxieteSVG,
        TroublesCognitifs: ToutouTroublesCognitifsSVG,
        Stress: ToutouStressSVG,
        TroublesSommeil: ToutouTroublesSommeilSVG,
      };

      return (
        <ActivitySplashScreen imageSrc={imageToys[type]} title={title} description={description}>
          <div className="activity-splash-screen__actions">
            <Button
              isStyled
              backgroundColor={EButtonColor.OUTLINE}
              className="activity-splash-screen__button"
              label="Attraper un autre toutou"
              onClick={() => goToPrevStep()}
            />
            <Button
              isStyled
              className="activity-splash-screen__button"
              label="Poursuivre ta visite"
              onClick={() => goToBarScene()}
            />
          </div>
        </ActivitySplashScreen>
      );
    }
  }

  return (
    <div className="activity-toys-machine">
      <div className="activity-toys-machine__container">
        <div className="activity-toys-machine__machine-wrapper">
          <SVGLoader src={MachineToutousSVG} className="activity-toys-machine__machine" />
          <SVGLoader src={MachineToutousOmbreSVG} className="activity-toys-machine__machine-shadow" />
          <div className="activity-toys-machine__window">
            <ToysMachineWindow stepNumber={stepNumber} isCraneDown={isCraneDown} isDropped={isDropped} />
          </div>
          <div className="activity-toys-machine__buttons">
            <ToysMachineButton
              onChange={(number) => toysInteraction.setStepNumberToys(number)}
              onDrop={() => onCraneDrop()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ActivityToysMachine;
