import React from 'react';
import ActivityToysMachine from 'component/activity-toys-machine/ActivityToysMachine';
import './ScreenToysMachine.scss';

/**
 * The main screen to display for the cocktail list activity.
 * Base on his state, it will show the splash screen or the activity screen.
 * @returns React.ReactElement
 */

const ScreenToysMachine = (): React.ReactElement => {
  return (
    <div className="screen-cocktail">
      <ActivityToysMachine />
    </div>
  );
};
export default ScreenToysMachine;
