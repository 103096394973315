import React, { useEffect, useState } from 'react';
import SVGLoader from 'component/svg/SVG';
import {
  MachineBrasCloseSVG,
  MachineBrasOpenSVG,
  MachineBrasTopSVG,
  ToutouAnxieteSVG,
  ToutouStressSVG,
  ToutouTroublesCognitifsSVG,
  ToutouTroublesSommeilSVG,
} from 'services/exports-list';
import './ToysMachineWindow.scss';

interface IProps {
  stepNumber: number;
  isDropped?: boolean;
  isCraneDown?: boolean;
}

const ToysMachineWindow = ({ stepNumber, isDropped = false, isCraneDown = false }: IProps): React.ReactElement => {
  const [craneNumber, setCurrentCraneNumber] = useState<number>(3);
  const [isCraneClose, setCraneAnimation] = useState<boolean>(false);
  const [isCraneDrop, setCraneHasDrop] = useState<boolean>(false);
  /**
   * Onload and on change of the step number state
   * Make that the crane never goes more than 4
   */
  useEffect((): void => {
    if (stepNumber < 5) {
      setCurrentCraneNumber(stepNumber);
    }
  }, [stepNumber]);

  /**
   * Make the crane "close" the grip when
   * the user select a toys
   */
  useEffect((): void => {
    if (isCraneDown) {
      setCraneAnimation(true);
      window?.setTimeout((): void => {
        setCraneHasDrop(true);
      }, 2000);
    }
  }, [isCraneDown]);

  return (
    <div className="toys-machine-window">
      <div
        className={`toys-machine-window__claw-wrapper --step-${stepNumber} ${isDropped && '--is-grabbing'} ${
          isCraneDown && '--is-dropped'
        }`}
      >
        <SVGLoader className="toys-machine-window__claw-top" src={MachineBrasTopSVG} />
        <div className="toys-machine-window__claw-rope" />
        <div className="toys-machine-window__claw">
          <SVGLoader
            className={`toys-machine-window__claw-open ${
              !isCraneDown && stepNumber !== 5 && !isCraneClose && 'd-block'
            }`}
            src={MachineBrasOpenSVG}
          />
          <SVGLoader
            className={`toys-machine-window__claw-close ${isCraneClose && !isCraneDrop && 'd-block'}`}
            src={MachineBrasCloseSVG}
          />
          <SVGLoader
            className={`toys-machine-window__claw-open ${isCraneClose && isCraneDrop && 'd-block'}`}
            src={MachineBrasOpenSVG}
          />
        </div>
      </div>
      <div className="toys-machine-window__toys-wrapper">
        <div
          className={`toys-machine-window__toy-element ${isDropped && craneNumber === 1 && '--is-choosen'} ${
            isDropped && stepNumber === 5 && '--is-dropped'
          }`}
        >
          <SVGLoader className="toys-machine-window__toys-claw --toy-1" src={ToutouAnxieteSVG} />
        </div>
        <div
          className={`toys-machine-window__toy-element ${isDropped && craneNumber === 2 && '--is-choosen'} ${
            isDropped && stepNumber === 5 && '--is-dropped'
          }`}
        >
          <SVGLoader className="toys-machine-window__toys-claw --toy-2" src={ToutouTroublesCognitifsSVG} />
        </div>
        <div
          className={`toys-machine-window__toy-element ${isDropped && craneNumber === 3 && '--is-choosen'} ${
            isDropped && stepNumber === 5 && '--is-dropped'
          }`}
        >
          <SVGLoader className="toys-machine-window__toys-claw --toy-3" src={ToutouStressSVG} />
        </div>
        <div
          className={`toys-machine-window__toy-element ${isDropped && craneNumber === 4 && '--is-choosen'} ${
            isDropped && stepNumber === 5 && '--is-dropped'
          }`}
        >
          <SVGLoader className="toys-machine-window__toys-claw --toy-4" src={ToutouTroublesSommeilSVG} />
        </div>
      </div>
    </div>
  );
};
export default ToysMachineWindow;
