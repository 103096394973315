import React from 'react';
import { Fade, Modal } from '@material-ui/core';
import SVGLoader from '../svg/SVG';
import Button from '../button/Button';
import './ActivityButton.scss';
import { CheckSVG } from 'services/exports-list';

interface IProps {
  id?: string;
  title?: string;
  text?: string;
  additionalText?: string;
  isActive?: boolean;
  isCompleted?: boolean;
  SVGImage?: string;
  extraClassName?: string;
  label?: string;
  onClickEvt?: () => void;
  children?: React.ReactNode;
}

const ActivityButton = ({
  id = '',
  isActive = false,
  isCompleted = false,
  title = '',
  text = '',
  additionalText = '',
  SVGImage = '',
  extraClassName = '',
  label = '',
  onClickEvt = () => {},
  children = null,
}: IProps): React.ReactElement => {
  return (
    <div id={id} className={`activity-button ${extraClassName} ${isActive && '--is-active'}`}>
      <SVGLoader className="activity-button__image" src={SVGImage} />
      <Modal closeAfterTransition hideBackdrop open={isActive}>
        <Fade in={isActive}>
          <div className="activity-detail__modal modal">
            {title && <h2 className="modal__title" dangerouslySetInnerHTML={{ __html: title }} />}
            {text && <p className="modal__text" dangerouslySetInnerHTML={{ __html: text }} />}
            {label && (
              <Button isStyled label={label} onClick={() => onClickEvt()}>
                {isCompleted && (
                  <span className="check-icon">
                    <SVGLoader src={CheckSVG} />
                  </span>
                )}
              </Button>
            )}
            {isCompleted && additionalText && <p className="modal__additionalText">{additionalText}</p>}
            {children && children}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default ActivityButton;
