import React, { useEffect, useState } from 'react';
import googleTagManager from '../../services/google-tag-manager';
import { EScene } from '../../enum/scene';
import ActivitySplashAction from 'component/activity-splash-actions/ActivitySplashAction';
import ActivityCocktail from 'component/activity-cocktail/ActivityCocktail';
import CocktailResult from 'component/cocktail-result/CocktailResult';
import { IReduxState } from 'interface/i-redux-state';
import './ScreenCocktail.scss';
import cocktailInteraction from 'services/cocktail-interaction';

/**
 * The main screen to display for the cocktail list activity.
 * Base on his state, it will show the splash screen or the activity screen.
 * @returns React.ReactElement
 */

interface IProps extends IReduxState {}

const ScreenCocktail = (props: IProps): React.ReactElement => {
  const { selectedIngredient } = props;
  const [hasAllIngredient, setHasAllIngredient] = useState<boolean>(false);
  const [isOnResultPage, setOnResultPage] = useState<boolean>(false);
  const [isResultDisplayed, setResultIsDisplayed] = useState<boolean>(false);

  window.addEventListener('resetActivity', (): void => {
    setHasAllIngredient(false);
    setOnResultPage(false);
    cocktailInteraction.setSelectedIngredient(null, 0);
    cocktailInteraction.setSelectedIngredient(null, 1);
    cocktailInteraction.setSelectedIngredient(null, 2);
  });

  useEffect(() => {
    if (selectedIngredient[0] && selectedIngredient[1] && selectedIngredient[2]) {
      setHasAllIngredient(true);
    }
  }, [JSON.stringify(selectedIngredient)]);

  useEffect(() => {
    if (isOnResultPage) {
      window?.setTimeout((): void => {
        setResultIsDisplayed(true);
      }, 4500);
    }
  }, [isOnResultPage]);

  const goToPrevStep: () => void = (): void => {
    googleTagManager.onRestartButtonClick(EScene.COCKTAIL);
    setResultIsDisplayed(false);
    setHasAllIngredient(false);
    setOnResultPage(false);
    cocktailInteraction.setSelectedIngredient(null, 0);
    cocktailInteraction.setSelectedIngredient(null, 1);
    cocktailInteraction.setSelectedIngredient(null, 2);
  };
  const goToNextStep: () => void = (): void => {
    if (!isOnResultPage) {
      googleTagManager.onContinueButtonClick(
        EScene.COCKTAIL,
        selectedIngredient[0],
        selectedIngredient[1],
        selectedIngredient[2]
      );
    }
    setOnResultPage(true);
  };
  const screenToDisplay: React.ReactNode = isOnResultPage ? (
    <CocktailResult ingredientList={selectedIngredient} onPreviousButton={() => goToPrevStep()} />
  ) : (
    <ActivityCocktail selectedIngredient={selectedIngredient} />
  );
  return (
    <div className="screen-cocktail">
      {screenToDisplay}
      {!isOnResultPage && hasAllIngredient && (
        <ActivitySplashAction
          isContinueDisabled={!hasAllIngredient || (!isResultDisplayed && isOnResultPage)}
          continueButtonLabel={isOnResultPage ? 'Continuer' : 'Voir mon mocktail'}
          onContinueButton={() => goToNextStep()}
        />
      )}
    </div>
  );
};
export default ScreenCocktail;
