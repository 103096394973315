import React from 'react';
import content from './content.json';
import { EButtonColor } from '../../enum/button';
import Button from '../button/Button';
import { EScene } from '../../enum/scene';
import googleTagManager from 'services/google-tag-manager';
import { EConsumption } from 'enum/user';
import ActivitySplashScreen from 'component/activity-splash-screen/ActivitySplashScreen';
import { CercleStressSVG } from 'services/exports-list';
import userInteraction from 'services/user-interaction';
import sceneInteraction from 'services/scene-interaction';
import './ScreenFreezerComplete.scss';

type Content = {
  description: string;
};

const ScreenFreezerComplete = (): React.ReactElement => {
  const userConsumptionType: EConsumption = userInteraction.getUserConsumption();
  const { description }: Content = userConsumptionType === EConsumption.VAPER ? content.vaper : content.smoker;

  const goToBarScreen = (): void => {
    googleTagManager.onQuitButtonClick(EScene.FREEZER);
    sceneInteraction.goToBarScreen(true);
  };

  const restartActivity = (): void => {
    googleTagManager.onRestartButtonClick(EScene.FREEZER);
    sceneInteraction.goToScreen(EScene.FREEZER);
  };

  return (
    <div className="screen-freezer-complete">
      <ActivitySplashScreen
        title="Ouff, as-tu ressenti du stress?"
        description={description}
        imageSrc={CercleStressSVG}
      >
        <div className="activity-splash-screen__actions">
          <Button
            isStyled
            backgroundColor={EButtonColor.OUTLINE}
            className="activity-splash-screen__button"
            label="Réessayer"
            onClick={() => restartActivity()}
          />
          <Button
            isStyled
            className="activity-splash-screen__button"
            label="Poursuivre ta visite"
            onClick={() => goToBarScreen()}
          />
        </div>
      </ActivitySplashScreen>
    </div>
  );
};
export default ScreenFreezerComplete;
