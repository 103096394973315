import React, { useState, useEffect } from 'react';
import H5AudioPlayer from 'react-h5-audio-player';
import _ from 'lodash';
import Button from '../button/Button';
import { EButtonColor } from 'enum/button';
import { EScene } from 'enum/scene';
import googleTagManager from 'services/google-tag-manager';
import { BoingMP3 } from 'services/exports-list';
import sceneInteraction from 'services/scene-interaction';
import './QuizzResults.scss';

interface IProps {
  answerState: {
    [key: number]: number;
  };
  results: Array<{
    description: string;
    image: string;
  }>;
  goToPrevStep: () => void;
}
const QuizzResults = ({ answerState, results, goToPrevStep }: IProps): React.ReactElement => {
  const [mostPopularAnswerIndex, setAnswerIndex] = useState<number>(0);
  const isMusicPlaying: boolean = sceneInteraction.isMusicEnabled();

  useEffect(() => {
    const answerList: Array<number> = Object.values(answerState);
    const mostPopular: string | number | undefined = _.head(_(answerList).countBy().entries().maxBy(_.last));
    setAnswerIndex((mostPopular as number) || 0);
  }, [answerState]);

  const goToBarScreen = (): void => {
    googleTagManager.onQuitButtonClick(EScene.QUIZZ);
    sceneInteraction.goToBarScreen(true);
  };

  return (
    <div className="quizz-results">
      {isMusicPlaying && <H5AudioPlayer src={BoingMP3} autoPlay />}
      <div className="quizz-results__container">
        <img
          alt=""
          aria-hidden="true"
          src={`${process.env.PUBLIC_URL}/assets/${results[mostPopularAnswerIndex].image}`}
          className="quizz-results__images"
        />
        <div className="quizz-results__wrapper">
          <p className="quizz-results__description">{results[mostPopularAnswerIndex].description}</p>
        </div>
      </div>
      <div className="quizz-results__actions">
        <Button
          isStyled
          backgroundColor={EButtonColor.OUTLINE}
          className="activity-splash-screen__button"
          label="Refaire le quizz"
          onClick={() => goToPrevStep()}
        />
        <Button
          isStyled
          className="activity-splash-screen__button"
          label="Poursuivre ta visite"
          onClick={() => goToBarScreen()}
        />
      </div>
    </div>
  );
};
export default QuizzResults;
