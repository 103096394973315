import React, { useEffect, useState } from 'react';
import H5AudioPlayer from 'react-h5-audio-player';
import useInterval from 'hooks/useInterval';
import utils from 'services/utils';
import { BombTickMP3 } from 'services/exports-list';
import sceneInteraction from 'services/scene-interaction';
import './BombTimer.scss';

interface IProps {
  second: number;
  isComplete: boolean;
  onFinish: (currentNumber: number) => void;
  onTimeRunningOut: () => void;
}

const BombTimer = ({ second = 60, isComplete = false, onFinish, onTimeRunningOut }: IProps): React.ReactElement => {
  const [timeLeft, setTimeLeft] = useState<number>(second || 60);
  const [isFinish, setIfFinish] = useState<boolean>(false);
  const isMusicEnabled = sceneInteraction.isMusicEnabled();

  useInterval(
    () => {
      if (!isFinish) {
        setTimeLeft(timeLeft - 1);
      }
    },
    isFinish ? null : 1000
  );

  /**
   * Onload and on change of `isComplete` props
   * and the `timeleft` state
   * Call the function to detect the state of the timer
   */
  useEffect((): void => {
    const isTimerComplete: boolean = isComplete || timeLeft <= 0;
    const isTimeRunningOut: boolean = !isComplete && timeLeft < second / 6;
    setIfFinish(isTimerComplete);
    if (isTimeRunningOut) {
      onTimeRunningOut();
    }
    if (isTimerComplete) {
      onFinish(timeLeft);
    }
  }, [isComplete, timeLeft]);
  return (
    <>
      {!isFinish && isMusicEnabled && <H5AudioPlayer src={BombTickMP3} autoPlay loop />}
      <div className="bomb-timer">{utils.formatTimeToString(timeLeft)}</div>
    </>
  );
};
export default BombTimer;
