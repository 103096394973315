import React from 'react';
import { useDrag } from 'react-dnd';
import { usePreview } from 'react-dnd-preview';
import { ECocktaiIngredientlList, ECocktailDND } from 'enum/cocktail';
import cocktailInteraction from 'services/cocktail-interaction';
import './CocktailIngredient.scss';

interface IProps {
  name: ECocktaiIngredientlList;
  id?: string;
  isDisabled?: boolean;
  onIngredientDrag: (name: ECocktaiIngredientlList | null) => void;
}

const CocktailIngredient = ({ name, id = '', isDisabled = false, onIngredientDrag }: IProps): React.ReactElement => {
  const [collected, dripTipPieceRef] = useDrag(
    () => ({
      type: ECocktailDND.INGREDIENT,
      item: { id: name },
      canDrag: () => {
        return !isDisabled;
      },
      collect: (monitor) => ({
        item: monitor.getItem(),
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [isDisabled]
  );

  const MobileDragPreview = () => {
    const { display, item, style } = usePreview() as any;
    if (!display || (item.id !== collected.item.id && item.id !== name)) {
      return null;
    }
    return (
      <div className="cocktail-ingredient__drag" style={style}>
        <img alt={name} src={cocktailInteraction.getCocktailByName(name)} className="cocktail-ingredient__drag-icon" />
      </div>
    );
  };

  if (collected.isDragging) {
    onIngredientDrag(name);
  }

  return (
    <>
      {collected.isDragging && <MobileDragPreview />}
      <div ref={dripTipPieceRef} className={`cocktail-ingredient ${isDisabled && '--is-disabled'}`} data-item={id}>
        <img alt={name} src={cocktailInteraction.getCocktailByName(name)} className="cocktail-ingredient__icon" />
      </div>
    </>
  );
};
export default CocktailIngredient;
