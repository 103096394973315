import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'component/button/Button';
import { IReduxState } from 'interface/i-redux-state';
import { EBarScene, EBarSceneVape, EScene } from 'enum/scene';
import { EConsumption } from 'enum/user';
import SVGLoader from 'component/svg/SVG';
import { ArrowSVG, CheckSVG } from 'services/exports-list';
import './NavigationBar.scss';

interface IProps {
  sceneIndex?: number;
  hasPointerEvents: boolean;
  onClickEvt?: (direction: string) => void;
}

const NavigationBar = ({
  sceneIndex = 0,
  hasPointerEvents = true,
  onClickEvt = () => {},
}: IProps): React.ReactElement => {
  const state: IReduxState = useSelector((newState: IReduxState) => newState);
  const sceneList: Array<string> = Object.keys(state.consumption === EConsumption.VAPER ? EBarSceneVape : EBarScene);
  const [isNextDisabled, setNextDisabled] = useState(false);
  const [isPreviousDisabled, setPreviousDisabled] = useState(false);
  const sceneListWithoutInside: Array<string> = sceneList.filter((item) => item !== EScene.INSIDE_BAR);

  useEffect(() => {
    if (sceneIndex >= sceneList.indexOf(EScene.END)) {
      setNextDisabled(true);
      setPreviousDisabled(false);
    } else if (sceneIndex < sceneList.indexOf(EScene.COCKTAIL)) {
      setPreviousDisabled(true);
      setNextDisabled(true);
    } else if (sceneIndex === sceneList.indexOf(EScene.COCKTAIL)) {
      setPreviousDisabled(true);
      setNextDisabled(false);
    } else {
      setNextDisabled(false);
      setPreviousDisabled(false);
    }
  }, [sceneIndex]);
  return (
    <div className={`navigation-bar ${hasPointerEvents ? 'pointer-events' : ''}`}>
      <Button isStyled className="rounded" isDisabled={isPreviousDisabled} onClick={() => onClickEvt('previous')}>
        <SVGLoader src={ArrowSVG} className="navigation-arrow-previous" />
      </Button>
      <div className="navigation-station-status-wrapper">
        {sceneListWithoutInside.map((item, index) => (
          <span
            key={item}
            className={
              // eslint-disable-next-line no-nested-ternary
              state.completedBarScene.includes(sceneList[index + 1] as EScene)
                ? 'done navigation-station-status'
                : sceneIndex - 1 === index
                ? 'active navigation-station-status'
                : 'navigation-station-status'
            }
          >
            <SVGLoader src={CheckSVG} />
          </span>
        ))}
      </div>
      <Button isStyled className="rounded" isDisabled={isNextDisabled} onClick={() => onClickEvt('next')}>
        <SVGLoader src={ArrowSVG} className="navigation-arrow-next" />
      </Button>
    </div>
  );
};

export default NavigationBar;
