import React from 'react';
import { Modal } from '@material-ui/core';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import DeepARMirror from '../deepar-mirror/DeepARMirror';
import { EScene } from '../../enum/scene';
import googleTagManager from 'services/google-tag-manager';
import { EConsumption } from 'enum/user';
import SVGLoader from 'component/svg/SVG';
import VisageSDKMirror from 'component/visagesdk-mirror/VisageSDKMirror';
import { MiroirFondSVG, MiroirSVG } from 'services/exports-list';
import userInteraction from 'services/user-interaction';
import sceneInteraction from 'services/scene-interaction';
import Button from 'component/button/Button';

import './ActivityMirror.scss';

interface IProps {
  isAfterMirror?: boolean;
}

const ActivityMirror = ({ isAfterMirror = false }: IProps): React.ReactElement => {
  const userConsumptionType: EConsumption = userInteraction.getUserConsumption();
  const currentIndex = userConsumptionType === EConsumption.VAPER ? 2 : 1;
  const backgroundStyle = {
    backgroundImage: `url(${MiroirFondSVG})`,
  };

  const phoneEvent = (): void => {
    googleTagManager.onPhoneButtonClick(EScene.MIRROR);
  };

  const goToBarScreen = (): void => {
    googleTagManager.onQuitButtonClick(EScene.MIRROR);
    sceneInteraction.goToBarScreen(true);
  };

  // @ts-ignore
  // @ts-ignore
  return (
    <div className="activity-mirror" style={backgroundStyle}>
      <div className="activity-mirror__mirror">
        <SVGLoader src={MiroirSVG} className="activity-mirror__mirror-img" />
        <div className="activity-mirror__mirror-content">
          <Modal
            closeAfterTransition
            disableEscapeKeyDown={false}
            onBackdropClick={(): boolean => {
              if (!isAfterMirror) {
                return false;
              }
              goToBarScreen();
              return false;
            }}
            open={isAfterMirror}
          >
            <SwitchTransition>
              <CSSTransition
                key={currentIndex}
                addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
                classNames="fade"
              >
                <div className="activity-mirror__modal modal">
                  {isAfterMirror && (
                    <>
                      <Button
                        className="modal-close"
                        aria-label="Fermer la fenêtre"
                        icon={['fas', 'times']}
                        onClick={() => goToBarScreen()}
                      />
                      {userConsumptionType === EConsumption.VAPER ? (
                        <>
                          <h3 className="mt-4">Un rat ???</h3>
                          <p className="mt-4">
                            Eh oui, sans le savoir, on est un peu comme des rats de laboratoire! On ne connaît pas
                            encore les impacts à long terme du vapotage sur le corps; les tests sont en cours! On sait
                            toutefois que les produits inhalés peuvent provoquer des problèmes respiratoires et de
                            santé.
                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                            Les produits inhalés en consommant une cigarette diminuent l’afflux de sang ET l’apport en
                            oxygène des cellules de la peau. La peau a donc plus de mal à se régénérer, elle devient
                            moins élastique et a plus de misère à soigner les blessures. Arrêter de fumer peut améliorer
                            significativement la santé de la peau et ralentir les effets du vieillissement prématuré.
                          </p>
                        </>
                      )}
                      <p className="mt-4">
                        Besoin de soutien ? La ligne J’ARRÊTE au{' '}
                        <a
                          className="activity-mirror__phone-number"
                          href="tel:+18665277383"
                          onClick={() => phoneEvent()}
                        >
                          1&nbsp;866&nbsp;527-7383
                        </a>{' '}
                        est là pour toi!
                      </p>
                    </>
                  )}
                </div>
              </CSSTransition>
            </SwitchTransition>
          </Modal>
          <SwitchTransition>
            <CSSTransition
              key={currentIndex}
              addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
              classNames="fade"
            >
              <>
                {currentIndex < 2 && <VisageSDKMirror isUnmount={isAfterMirror} />}
                {currentIndex >= 2 && <DeepARMirror isUnmount={isAfterMirror} />}
              </>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    </div>
  );
};
export default ActivityMirror;
