import React from 'react';
import googleTagManager from '../../services/google-tag-manager';
import Button from 'component/button/Button';
import SVGLoader from 'component/svg/SVG';
import { SplashScreenSVG } from 'services/exports-list';
import sceneInteraction from 'services/scene-interaction';
import './SplashScreen.scss';
import { EButtonSize } from 'enum/button';

const SplashScreen = (): React.ReactElement => {
  const enterBar = () => {
    googleTagManager.onStartButtonClick();

    sceneInteraction.goToNextScreen();
    sceneInteraction.toggleMusicPlaying(true);
  };

  return (
    <div className="splash-screen">
      <div className="splash-screen__logo">
        <SVGLoader src={SplashScreenSVG} className="splash-screen__logo-svg" />
      </div>
      <Button isStyled size={EButtonSize.MEDIUM} onClick={() => enterBar()} label="Débuter" />
    </div>
  );
};
export default SplashScreen;
