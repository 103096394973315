import React, { useState, useEffect } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import H5AudioPlayer from 'react-h5-audio-player';
import { MetalBreakingMP3, VapoteuseTableAnimationSVG } from 'services/exports-list';
import ActivityVapePuzzle from 'component/activity-vape-puzzle/ActivityVapePuzzle';
import SVGLoader from 'component/svg/SVG';
import sceneInteraction from 'services/scene-interaction';
import './ActivityVapeAnimation.scss';

interface IProps {
  onFinishPuzzleEvt?: () => void;
}

const ActivityVapeAnimation = ({ onFinishPuzzleEvt = () => {} }: IProps): React.ReactElement => {
  const isMusicEnabled = sceneInteraction.isMusicEnabled();
  const [isMainActivityScreen, toggleMainActivityScreen] = useState<boolean>(false);

  useEffect(() => {
    window?.setTimeout((): void => {
      toggleMainActivityScreen(true);
    }, 5000);
  }, []);

  return (
    <div className={`activity-vape-animation ${!isMainActivityScreen && '--is-animation'}`}>
      <SwitchTransition>
        <CSSTransition
          key={`isDisplayed-${isMainActivityScreen}`}
          addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
          classNames="fade"
        >
          {!isMainActivityScreen ? (
            <div className="activity-vape-animation__table-scene">
              {isMusicEnabled && <H5AudioPlayer src={MetalBreakingMP3} autoPlay />}
              <SVGLoader src={VapoteuseTableAnimationSVG} className="activity-vape-animation__table-animation" />
            </div>
          ) : (
            <ActivityVapePuzzle
              onFinishPuzzleEvt={() => {
                onFinishPuzzleEvt();
              }}
            />
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};
export default ActivityVapeAnimation;
