import React, { useEffect, useState } from 'react';
import SVGLoader from 'component/svg/SVG';
import { CadenasBarreSVG, CadenasBoiteSVG } from 'services/exports-list';
import LockerButton from 'component/locker-button/LockerButton';
import BombTimer from 'component/bomb-timer/BombTimer';
import './ActivityFridgeLocker.scss';

interface IProps {
  onFinish: (currentNumber: number) => void;
}

const ActivityFridgeLocker = ({ onFinish }: IProps): React.ReactElement => {
  const [firstNumber, setFirstNumber] = useState<number>(0);
  const [secondNumber, setSecondNumber] = useState<number>(0);
  const [thirdNumber, setThirdNumber] = useState<number>(0);
  const [isUnlocked, setLockerIsUnlocked] = useState<boolean>(false);
  const [isTimeRunningOut, setTimeRunningOut] = useState<boolean>(false);

  /**
   * Onload and on change of each locker number state
   * Call the function to start the timer.
   */
  useEffect((): void => {
    if (firstNumber === 0 && secondNumber === 4 && thirdNumber === 8) {
      setLockerIsUnlocked(true);
    } else {
      setLockerIsUnlocked(false);
    }
  }, [firstNumber, secondNumber, thirdNumber]);

  return (
    <div
      className={`activity-fridge-locker ${isUnlocked && '--is-open'} ${
        isTimeRunningOut && !isUnlocked && '--is-time-running-out'
      }`}
    >
      <SVGLoader src={CadenasBarreSVG} className="activity-fridge-locker__lock-top" />
      <SVGLoader src={CadenasBoiteSVG} className="activity-fridge-locker__lock-bottom" />
      <div className="activity-fridge-locker__button-wrapper">
        <BombTimer
          second={20}
          onFinish={(timeLeft) => onFinish(timeLeft)}
          onTimeRunningOut={() => setTimeRunningOut(true)}
          isComplete={isUnlocked}
        />
        <LockerButton onChange={(number) => setFirstNumber(number)} />
        <LockerButton onChange={(number) => setSecondNumber(number)} />
        <LockerButton onChange={(number) => setThirdNumber(number)} />
      </div>
    </div>
  );
};
export default ActivityFridgeLocker;
