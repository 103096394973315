import React from 'react';

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useSelector } from 'react-redux';

import ScreenRegister from 'component/screen-register/ScreenRegister';
import { EScene } from 'enum/scene';
import { IReduxState } from 'interface/i-redux-state';
import ActionBar from 'component/action-bar/ActionBar';
import sceneInteraction from 'services/scene-interaction';
import ScreenStart from 'component/screen-start/ScreenStart';
import ScreenBar from 'component/screen-bar/ScreenBar';
import ScreenCocktail from 'component/screen-cocktail/ScreenCocktail';
import ScreenFridge from 'component/screen-fridge/ScreenFridge';
import ScreenQuizz from 'component/screen-quizz/ScreenQuizz';
import ScreenMirror from 'component/screen-mirror/ScreenMirror';
import ScreenFreezerComplete from 'component/screen-freezer-complete/ScreenFreezerComplete';
import ScreenVapePuzzle from 'component/screen-vape-puzzle/ScreenVapePuzzle';
import ScreenToysMachine from 'component/screen-toys-machine/ScreenToysMachine';

import './SceneLoader.scss';
import ScreenContestEnding from 'component/screen-contest-ending/ScreenContestEnding';

/**
 * Component that will load the good scene base on the Redux Store `currentScene` params.
 * @returns React.ReactElement
 */

export const SceneLoader = (): React.ReactElement => {
  const state: IReduxState = useSelector((newState: IReduxState) => newState);
  const sceneToLoad = (): React.ReactElement => {
    switch (state.currentScene) {
      case EScene.START:
        return <ScreenStart />;
      case EScene.USER_REGISTRATION:
        return <ScreenRegister {...state} />;
      case EScene.INSIDE_BAR:
        return <ScreenBar {...state} />;
      case EScene.COCKTAIL:
        return <ScreenCocktail {...state} />;
      case EScene.TOYS_MACHINE:
        return <ScreenToysMachine />;
      case EScene.MIRROR:
        return <ScreenMirror />;
      case EScene.FREEZER:
        return <ScreenFridge />;
      case EScene.FREEZER_COMPLETE:
        return <ScreenFreezerComplete />;
      case EScene.VAPE_PUZZLE:
        return <ScreenVapePuzzle />;
      case EScene.QUIZZ:
        return <ScreenQuizz />;
      case EScene.END:
        return <ScreenContestEnding />;
      default:
        return (
          <button
            type="button"
            onClick={() => {
              sceneInteraction.goToNextScreen();
            }}
          >
            On click
          </button>
        );
    }
  };

  return (
    <div className="scene-loader">
      <ActionBar isMusicPlaying={state.isMusicPlaying} />
      <SwitchTransition>
        <CSSTransition
          key={state.currentScene}
          addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
          classNames="fade"
        >
          {sceneToLoad()}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default SceneLoader;
