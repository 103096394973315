import React from 'react';
import { EButtonColor } from '../../enum/button';
import googleTagManager from '../../services/google-tag-manager';
import { EScene } from '../../enum/scene';
import Button from 'component/button/Button';
import { EConsumption } from 'enum/user';
import userInteraction from 'services/user-interaction';
import sceneInteraction from 'services/scene-interaction';
import { BackgroundRecommancerSVG, BilletFumeurSVG, BilletVapoteurSVG } from 'services/exports-list';
import SVGLoader from 'component/svg/SVG';
import ScreenEndTop from 'component/screen-end-top/ScreenEndTop';
import './ScreenEnd.scss';

const ScreenEnd = (): React.ReactElement => {
  const bottomBackgroundImage = {
    backgroundImage: `url(${BackgroundRecommancerSVG})`,
  };
  const userConsumptionType: EConsumption = userInteraction.getUserConsumption();
  const goToRestartProfile = (): void => {
    googleTagManager.onRestartButtonClick(EScene.END);
    sceneInteraction.goToRestartProfile();
  };

  return (
    <div className="screen-end">
      <div className="screen-end__top">
        <ScreenEndTop />
      </div>
      <div className="screen-end__bottom" style={bottomBackgroundImage}>
        <SVGLoader
          src={userConsumptionType === EConsumption.VAPER ? BilletFumeurSVG : BilletVapoteurSVG}
          className="screen-end__ticket"
        />
        <div className="screen-end__content-description">
          {userConsumptionType === EConsumption.VAPER ? (
            <>
              Envie de visiter le Nico-bar à nouveau et d&apos;apprendre des choses la cigarette ? Clique sur
              recommencer pour visiter le bar sur ce sujet!
            </>
          ) : (
            <>
              Envie de visiter le Nico-bar à nouveau et d&apos;apprendre des choses le vapotage ? Clique sur recommencer
              pour visiter le bar sur ce sujet!
            </>
          )}
          <Button
            isStyled
            label="Recommencer"
            className="screen-end__retry-button"
            backgroundColor={EButtonColor.OUTLINE}
            onClick={() => goToRestartProfile()}
          />
        </div>
      </div>
    </div>
  );
};
export default ScreenEnd;
