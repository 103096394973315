import React, { useEffect, useState } from 'react';
import { EVapePiece } from 'enum/puzzle';

import './VapePieceInformation.scss';

/**
 * A small popup to display information on each piece of the vape
 * @returns React.ReactElement
 */

interface IProps {
  isDripDragging?: boolean;
  isPodDragging?: boolean;
  isBatteryDragging?: boolean;
  isPowerDragging?: boolean;
}

const VapePieceInformation = ({
  isDripDragging = false,
  isPodDragging = false,
  isBatteryDragging = false,
  isPowerDragging = false,
}: IProps): React.ReactElement => {
  const [selectedPiece, setSelectedPiece] = useState<EVapePiece | null>(null);
  useEffect((): void => {
    if (isDripDragging) {
      setSelectedPiece(EVapePiece.DRIP_TIP);
    } else if (isPodDragging) {
      setSelectedPiece(EVapePiece.LIQUID_POD);
    } else if (isBatteryDragging) {
      setSelectedPiece(EVapePiece.BATTERY);
    } else if (isPowerDragging) {
      setSelectedPiece(EVapePiece.POWER_INDICATOR);
    }
  }, [isDripDragging, isPodDragging, isBatteryDragging, isPowerDragging]);

  return (
    <div className="vape-piece-information">
      {selectedPiece === EVapePiece.DRIP_TIP && (
        <p>
          <span>Embout buccal:</span> Utilisé pour inhaler l’aérosol contenant des produits cancérigènes et des
          particules de métal provenant de l&apos;élément chauffant.
        </p>
      )}
      {selectedPiece === EVapePiece.LIQUID_POD && (
        <p>
          <span>Réservoir:</span> Contient plus de 60 composés chimiques différents dont certains peuvent être toxiques.
        </p>
      )}
      {selectedPiece === EVapePiece.BATTERY && (
        <p>
          <span>Pile:</span> La pile au lithium peut exploser spontanément lorsqu’elle est chauffée, laissée au soleil
          ou même dans la poche.
        </p>
      )}
      {selectedPiece === EVapePiece.POWER_INDICATOR && (
        <p>
          <span>Élément chauffant:</span> Transforme le e-liquide en aérosol, qu’on inhale, dont une partie peut se
          coller aux poumons.
        </p>
      )}
      {!selectedPiece && (
        <p>
          Aie Aie Aie! La vapoteuse est brisée, répare-la pour t’excuser! Glisse et dépose les pièces et découvre leur
          fonction.
        </p>
      )}
    </div>
  );
};

export default VapePieceInformation;
