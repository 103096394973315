import React from 'react';
import ActivityQuizz from 'component/activity-quizz/ActivityQuizz';
import './ScreenQuizz.scss';

/**
 * The main screen to display for the quizz activity
 * Base on his state, it will show the splash screen or the activity screen.
 * @returns React.ReactElement
 */

const ScreenQuizz = (): React.ReactElement => {
  return (
    <div className="screen-quizz">
      <ActivityQuizz />
    </div>
  );
};
export default ScreenQuizz;
