import React, { useEffect, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import quizzConsumption from './quizz-consumption.json';
import { EScene } from '../../enum/scene';
import googleTagManager from 'services/google-tag-manager';
import ActivitySplashAction from 'component/activity-splash-actions/ActivitySplashAction';
import QuizzQuestion from 'component/quizz-question/QuizzQuestion';
import QuizzResults from 'component/quizz-results/QuizzResults';
import sceneInteraction from 'services/scene-interaction';
import './ActivityQuizz.scss';

interface answerState {
  [key: number]: number;
}

// @ToDo : quizz by consumption adjustment for vaper
const ActivityQuizz = (): React.ReactElement => {
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const [quizzState, setQuizzState] = useState<answerState>({});
  const [isContinueEnabled, setContinueEnabled] = useState<boolean>(false);
  const quizz = quizzConsumption;
  const isOnQuizzSection: boolean = currentQuestion < quizz.question.length;

  const updateQuizzState = (index: number, answer: number): void => {
    const currentState: answerState = quizzState;
    currentState[index] = answer;
    setContinueEnabled(true);
    setQuizzState(currentState);
  };

  const onContinueButton = (): void => {
    if (isOnQuizzSection) {
      const isQuizzIsFinished: boolean = quizz.question.length > Object.keys(quizzState).length;
      if (isQuizzIsFinished) {
        googleTagManager.onContinueButtonClick(EScene.QUIZZ, null, null, null);
        setContinueEnabled(false);
      }
      setCurrentQuestion(currentQuestion + 1);
    } else {
      sceneInteraction.goToBarScreen(true);
    }
  };

  const resetActivity = (): void => {
    googleTagManager.onRestartButtonClick(EScene.QUIZZ);
    setCurrentQuestion(0);
    setContinueEnabled(false);
    setQuizzState({});
  };

  useEffect(() => {
    const resetHandler = () => {
      const currentScene: EScene = sceneInteraction.getBarScene();
      if (currentScene === EScene.QUIZZ) googleTagManager.onCancelButtonClick(EScene.QUIZZ, currentQuestion);
      setCurrentQuestion(0);
      setContinueEnabled(false);
      setQuizzState({});
    };

    window.addEventListener('resetActivity', resetHandler);

    return () => {
      window.removeEventListener('resetActivity', resetHandler);
    };
  }, [currentQuestion]);

  return (
    <div className="activity-quizz">
      <div className="activity-quizz__wrapper">
        <SwitchTransition>
          <CSSTransition
            key={currentQuestion}
            addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
            classNames="fade"
          >
            {isOnQuizzSection ? (
              <QuizzQuestion
                {...quizz.question[currentQuestion]}
                onSubmitAnswer={(answerIndex) => updateQuizzState(currentQuestion, answerIndex)}
              />
            ) : (
              <QuizzResults answerState={quizzState} results={quizz.results} goToPrevStep={() => resetActivity()} />
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
      {isOnQuizzSection && (
        <ActivitySplashAction
          continueButtonLabel="Suivant"
          isContinueDisabled={!isContinueEnabled}
          onContinueButton={() => onContinueButton()}
        />
      )}
    </div>
  );
};
export default ActivityQuizz;
