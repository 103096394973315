import React, { useState } from 'react';
import CocktailBarman from 'component/cocktail-barman/CocktailBarman';
import CocktailList from 'component/cocktail-list/CocktailList';
import { ECocktaiIngredientlList } from 'enum/cocktail';
import cocktailInteraction from 'services/cocktail-interaction';
import './ActivityCocktail.scss';

interface IProps {
  selectedIngredient: Array<ECocktaiIngredientlList>;
}
const ActivityCocktail = ({ selectedIngredient }: IProps): React.ReactElement => {
  const [dragIngredient, setSelectedIngredient] = useState<ECocktaiIngredientlList | null>(null);
  const ingredientDescription = cocktailInteraction.getIngredientWhereToFind(dragIngredient);
  return (
    <div className="activity-cocktail">
      <CocktailList
        selectedIngredient={selectedIngredient}
        onIngredientDrag={(ingredient) => setSelectedIngredient(ingredient)}
      />
      <CocktailBarman />
      <div className="activity-cocktail__message-wrapper">
        <div className="activity-cocktail__message" dangerouslySetInnerHTML={{ __html: ingredientDescription }} />
      </div>
    </div>
  );
};
export default ActivityCocktail;
