import React from 'react';
import { BarTabletSVG } from 'services/exports-list';
import SVGLoader from 'component/svg/SVG';
import { ECocktaiIngredientlList } from 'enum/cocktail';
import CocktailIngredient from 'component/cocktail-ingredient/CocktailIngredient';
import userInteraction from 'services/user-interaction';
import cocktailInteraction from 'services/cocktail-interaction';
import './CocktailList.scss';

interface IProps {
  selectedIngredient: Array<ECocktaiIngredientlList>;
  onIngredientDrag: (name: ECocktaiIngredientlList | null) => void;
}

const CocktailList = ({ selectedIngredient, onIngredientDrag }: IProps): React.ReactElement => {
  const ingredientsList: Array<ECocktaiIngredientlList> = cocktailInteraction.getIngredientListByConsumption(
    userInteraction.getUserConsumption()
  );
  const ingredientsTablet = ingredientsList.map((ingredient: ECocktaiIngredientlList, index) => {
    let divElement: React.ReactNode = '';
    if (
      (index + 1) % 4 === 0 ||
      (index === ingredientsList.length && index % 4 !== 0) ||
      (index === ingredientsList.length - 1 && index % 4 !== 0)
    ) {
      divElement = <SVGLoader src={BarTabletSVG} className="cocktail-list__tablet" />;
    }
    return (
      <>
        <CocktailIngredient
          isDisabled={selectedIngredient.includes(ingredient)}
          onIngredientDrag={onIngredientDrag}
          name={ingredient as ECocktaiIngredientlList}
          id={ingredient}
        />
        {divElement}
      </>
    );
  });

  return (
    <div className="cocktail-list">
      <div className="cocktail-list__container">
        <div className="cocktail-list__tablet-wrapper">{ingredientsTablet}</div>
      </div>
    </div>
  );
};
export default CocktailList;
