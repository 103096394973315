import React, { useEffect, useState } from 'react';
import googleTagManager from 'services/google-tag-manager';
import Button from 'component/button/Button';
import RegistrationCard from 'component/registration-card/RegistrationCard';
import { IReduxState } from 'interface/i-redux-state';
import { DoorManSVG, WallTextureSVG } from 'services/exports-list';
import sceneInteraction from 'services/scene-interaction';
import './ScreenRegister.scss';

interface IProps extends IReduxState {}

const ScreenRegister = (props: IProps): React.ReactElement => {
  const { consumption } = props;
  const backgroundStyle = {
    backgroundImage: `url(${WallTextureSVG})`,
  };
  const [isContinueDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    setIsDisabled(!consumption);
  }, [consumption]);

  useEffect(() => {
    setIsDisabled(true);
  }, []);

  const onButtonClick = () => {
    googleTagManager.onEnterButtonClick();
    sceneInteraction.goToNextScreen();
  };

  return (
    <div className="screen-register" style={backgroundStyle}>
      <div className="screen-register__register-section">
        <div className="screen-register__register-text">
          <h1 className="screen-register__register-title">Bienvenue au Nico-Bar!</h1>
          <p className="screen-register__register-content">
            Découvre le tout nouveau concept de notre bar où l’on s’inspire des ingrédients toxiques qui se trouvent
            dans la vape et la cigarette pour créer nos mocktails!
            <br />
            <br /> Découvre nos mocktails signatures avec leurs ingrédients toxiques, leurs effets sur le corps et leurs
            impacts sur la santé mentale. Explore les différentes stations pour en savoir plus. Tu vas voir, on a pensé
            à tout; tu ne voudras plus quitter notre établissement!
          </p>
          <p className="screen-register__register-content">
            <span>Désactive le son</span> en cliquant sur l&apos;icône en haut à droite.
          </p>
        </div>
        <RegistrationCard />
        <Button
          isStyled
          label="Entrer"
          onClick={!isContinueDisabled ? () => onButtonClick() : () => {}}
          isDisabled={isContinueDisabled}
        />
      </div>
      <img src={DoorManSVG} alt="Doorman" aria-hidden="true" className="screen-register__doorman" />
    </div>
  );
};
export default ScreenRegister;
