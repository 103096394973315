import React, { ReactElement, useState } from 'react';
import Button from '../button/Button';
import googleTagManager from '../../services/google-tag-manager';
import { EScene } from '../../enum/scene';
import { EButtonColor } from '../../enum/button';
import ActivitySplashScreen from 'component/activity-splash-screen/ActivitySplashScreen';
import { ScreenVapeVapoteuseSVG } from 'services/exports-list';
import sceneInteraction from 'services/scene-interaction';
import ActivityVapeAnimation from 'component/activity-vape-animation/ActivityVapeAnimation';
import './ScreenVapePuzzle.scss';

/**
 * The main screen to display for the vape puzzle activity.
 * Base on his state, it will show the splash screen or the activity screen.
 * @returns React.ReactElement
 */

const ScreenVapePuzzle = (): React.ReactElement => {
  // const [isSplashScreen, setHasSplashScreen] = useState(true);
  const [isPuzzlePlace, setPuzzlePlace] = useState(false);

  window.addEventListener('resetActivity', (): void => {
    setPuzzlePlace(false);
  });

  const goToBarScreen = (): void => {
    googleTagManager.onQuitButtonClick(EScene.VAPE_PUZZLE);
    sceneInteraction.goToBarScreen(true);
  };

  const resetActivity = (): void => {
    googleTagManager.onRestartButtonClick(EScene.VAPE_PUZZLE);
    setPuzzlePlace(false);
  };

  const VapeContent = (): ReactElement => {
    if (isPuzzlePlace) {
      return (
        <ActivitySplashScreen
          title="Vape…eur d’eau ??"
          description="Produits toxiques, plastiques, métaux lourds… Finalement, la boucane qui s’échappe d’une vape est loin d’être de la vapeur d’eau! Vapoter peut causer des dommages irréversibles aux poumons, aux reins, au foie et plus encore !"
          imageSrc={ScreenVapeVapoteuseSVG}
        >
          <div className="activity-splash-screen__actions">
            <Button
              isStyled
              backgroundColor={EButtonColor.OUTLINE}
              className="activity-splash-screen__button"
              label="Recommencer"
              onClick={() => resetActivity()}
            />
            <Button
              isStyled
              className="activity-splash-screen__button"
              label="Poursuivre ta visite"
              onClick={() => goToBarScreen()}
            />
          </div>
        </ActivitySplashScreen>
      );
    }
    return <ActivityVapeAnimation onFinishPuzzleEvt={() => setPuzzlePlace(true)} />;
  };

  return (
    <div className="screen-vape-puzzle">
      <VapeContent />
    </div>
  );
};
export default ScreenVapePuzzle;
