import React, { useState } from 'react';

import ActivityFridge from 'component/activity-fridge/ActivityFridge';
// import ActivitySplashAction from 'component/activity-splash-actions/ActivitySplashAction';
import sceneInteraction from 'services/scene-interaction';
// import googleTagManager from 'services/google-tag-manager';

import './ScreenFridge.scss';

/**
 * The main screen to display for the locked fridge activity
 * Base on his state, it will show the splash screen or the activity screen.
 * @returns React.ReactElement
 */

const ScreenFridge = (): React.ReactElement => {
  // const [isActionEnabled, setActionEnabled] = useState(false);
  const [isSuccessful, setisSuccessful] = useState(false);
  const onFinish = (timeLeft: number): void => {
    if (timeLeft <= 0) {
      // googleTagManager.onFreezerActivityComplete(false);
      // setActionEnabled(true);
      sceneInteraction.goToNextScreen();
    } else {
      setisSuccessful(true);
      // googleTagManager.onFreezerActivityComplete(true);
      window?.setTimeout(() => {
        sceneInteraction.goToNextScreen();
      }, 3000);
    }
  };
  return (
    <div className="screen-fridge">
      <ActivityFridge isSuccessful={isSuccessful} onFinish={(timeLeft: number) => onFinish(timeLeft)} />
    </div>
  );
};
export default ScreenFridge;
