import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { IReduxState } from '../../interface/i-redux-state';
import { EConsumption } from '../../enum/user';
import { EBarScene, EBarSceneVape, EScene } from 'enum/scene';
import { EButtonColor } from 'enum/button';
import sceneInteraction from 'services/scene-interaction';
import { MusicOffSVG, MusicOnSVG, SingleLogoSVG, SurveyLogoSVG, LogoSVG, CloseSVG } from 'services/exports-list';
import googleTagManager from 'services/google-tag-manager';
import SVGLoader from 'component/svg/SVG';
import Button from 'component/button/Button';

import './ActionBar.scss';

interface IProps {
  isMusicPlaying: boolean;
}

/**
 * Component that will display the "exit" and "mute" button
 * @returns React.ReactElement
 */

export const ActionBar = ({ isMusicPlaying }: IProps): React.ReactElement => {
  const state: IReduxState = useSelector((newState: IReduxState) => newState);
  const today = new Date().getTime();
  const validUntil = new Date('2024-03-07').getTime();
  const isSurvey: boolean = process.env.REACT_APP_ENV === 'survey' && validUntil > today;
  const isVirtual: boolean = process.env.REACT_APP_ENV === 'virtual';
  const sceneList: Array<string> = Object.keys(state.consumption === EConsumption.VAPER ? EBarSceneVape : EBarScene);

  const exitActivity = (): void => {
    if (state.currentScene !== EScene.QUIZZ) googleTagManager.onCancelButtonClick(state.currentScene, null);
    const event = new Event('resetActivity');
    window.dispatchEvent(event);
    sceneInteraction.goToBarScreen(true);
  };

  const goToEnd = (): void => {
    googleTagManager.onDefiButtonClick();
    sceneInteraction.animateToScene(EScene.END);
  };
  return (
    <div className={`action-bar ${EScene.START === state.currentScene && isVirtual ? 'action-bar__only-music' : ''}`}>
      {isSurvey && (
        <a
          className="action-bar__logo-and-survey"
          href={process.env.REACT_APP_NICOBAR_SURVEY_URL}
          target="_blank"
          rel="noreferrer"
          // onClick={() => googleTagManager.onClickOnChallenge('header')}
          aria-label="Vous allez être rediriger vers le sondage"
        >
          <SVGLoader src={SurveyLogoSVG} />
          <span className="action-bar__exit-button">
            <FontAwesomeIcon icon={['fas', 'sign-out']} />
          </span>
        </a>
      )}

      {!isVirtual && (
        <a
          className="action-bar__logo-and-exit"
          href={process.env.REACT_APP_NICOBAR_MANY_CHAT_URL}
          target="_blank"
          rel="noreferrer"
          // onClick={() => googleTagManager.onClickOnChallenge('header')}
          aria-label="Vous allez être rediriger sur le site du nicobar"
        >
          <SVGLoader src={SingleLogoSVG} />
          <span className="action-bar__exit-button">
            <FontAwesomeIcon icon={['fas', 'sign-out']} />
          </span>
        </a>
      )}

      {EScene.START !== state.currentScene && isVirtual && (
        <div>
          <SVGLoader src={LogoSVG} />
        </div>
      )}

      <div>
        {EScene.INSIDE_BAR === state.currentScene && (
          <Button isStyled label="Défi" backgroundColor={EButtonColor.OUTLINE} onClick={() => goToEnd()} />
        )}
        {(EScene.USER_REGISTRATION === state.currentScene || EScene.INSIDE_BAR === state.currentScene) && (
          <Button
            isStyled
            backgroundColor={EButtonColor.OUTLINE}
            onClick={() => sceneInteraction.toggleMusicPlaying(!isMusicPlaying)}
            className="rounded"
          >
            <SVGLoader src={isMusicPlaying ? MusicOnSVG : MusicOffSVG} />
          </Button>
        )}
        {sceneList.includes(state.currentScene) && state.currentScene !== EScene.INSIDE_BAR && (
          <Button isStyled backgroundColor={EButtonColor.OUTLINE} onClick={() => exitActivity()} className="rounded">
            <SVGLoader src={CloseSVG} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default ActionBar;
