import React from 'react';

import './ActivitySplashScreen.scss';

interface IProps {
  imageSrc: string;
  title: string;
  description?: string;
  children?: React.ReactNode;
}
const ActivitySplashScreen = ({ imageSrc, title, description, children }: IProps): React.ReactElement => {
  return (
    <div className="activity-splash-screen">
      <div className="activity-splash-screen__container">
        <img alt="" src={imageSrc} className="activity-splash-screen__image" />
        <div className="activity-splash-screen__content">
          <h3 className="activity-splash-screen__content-title" dangerouslySetInnerHTML={{ __html: title }} />
          {description && (
            <p
              className="activity-splash-screen__content-description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
      </div>
      {children}
    </div>
  );
};
export default ActivitySplashScreen;
