import React, { useState } from 'react';
import { EScene } from '../../enum/scene';
import googleTagManager from 'services/google-tag-manager';
import ActivitySplashAction from 'component/activity-splash-actions/ActivitySplashAction';
import ActivityMirrorAnimation from 'component/activity-mirror-animation/ActivityMirrorAnimation';
import sceneInteraction from 'services/scene-interaction';
import './ScreenMirror.scss';

/**
 * The main screen to display for the mirror activity.
 * Base on his state, it will show the splash screen or the activity screen.
 * @returns React.ReactElement
 */

const ScreenMirror = (): React.ReactElement => {
  const [isInsideMirrorActivity, setIsInsideMirrorActivity] = useState<boolean>(true);
  const [isContinueDisabled, setToggleContinue] = useState<boolean>(true);

  window?.setTimeout(() => {
    setToggleContinue(false);
  }, 10000);

  const goToNextStep: () => void = (): void => {
    if (!isInsideMirrorActivity) {
      googleTagManager.onQuitButtonClick(EScene.MIRROR);
      sceneInteraction.goToBarScreen(true);
    } else if (isInsideMirrorActivity) {
      googleTagManager.onContinueButtonClick(EScene.MIRROR, null, null, null);
      setIsInsideMirrorActivity(false);
    } else {
      googleTagManager.onContinueButtonClick(EScene.MIRROR, null, null, null);
      setIsInsideMirrorActivity(true);
      setToggleContinue(true);
      window?.setTimeout(() => {
        setToggleContinue(false);
      }, 16000);
    }
  };
  return (
    <div className="screen-mirror">
      <ActivityMirrorAnimation isAfterMirror={!isInsideMirrorActivity} />
      {!isContinueDisabled && <ActivitySplashAction onContinueButton={() => !isContinueDisabled && goToNextStep()} />}
    </div>
  );
};
export default ScreenMirror;
