import React from 'react';
import { BarBarmanSVG, BarChiffre1SVG, BarChiffre2SVG, BarChiffre3SVG } from 'services/exports-list';
import SVGLoader from 'component/svg/SVG';
import CocktailDropZone from 'component/cocktail-drop-zone/CocktailDropZone';
import './CocktailBarman.scss';

const CocktailBarman = (): React.ReactElement => {
  return (
    <div className="cocktail-barman">
      <div className="cocktail-barman__container">
        <div className="cocktail-barman__table-wrapper">
          <SVGLoader src={BarBarmanSVG} className="cocktail-barman__table" />
          <div className="cocktail-barman__drop-zone">
            <CocktailDropZone img={BarChiffre1SVG} number={1} />
            <CocktailDropZone img={BarChiffre2SVG} number={2} />
            <CocktailDropZone img={BarChiffre3SVG} number={3} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CocktailBarman;
