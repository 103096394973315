import React, { useState, useEffect } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import H5AudioPlayer from 'react-h5-audio-player';
import sceneInteraction from 'services/scene-interaction';
import { ChasseDEauMP3, PorteMurSVG } from 'services/exports-list';
import ActivityMirror from 'component/activity-mirror/ActivityMirror';
import SVGLoader from 'component/svg/SVG';
import './ActivityMirrorAnimation.scss';

interface IProps {
  isAfterMirror?: boolean;
}

const ActivityMirrorAnimation = ({ isAfterMirror }: IProps): React.ReactElement => {
  const [isMainActivityScreen, toggleMainActivityScreen] = useState<boolean>(false);
  const isMusicEnabled = sceneInteraction.isMusicEnabled();

  useEffect(() => {
    window?.setTimeout((): void => {
      toggleMainActivityScreen(true);
    }, 5000);
  }, []);

  return (
    <div className="activity-mirror-animation">
      <SwitchTransition>
        <CSSTransition
          key={`isDisplayed-${isMainActivityScreen}`}
          addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
          classNames="fade"
        >
          {!isMainActivityScreen ? (
            <div className="activity-mirror-animation__door-scene">
              {isMusicEnabled && <H5AudioPlayer src={ChasseDEauMP3} autoPlay />}
              <SVGLoader src={PorteMurSVG} className="activity-mirror-animation__open-door-screen" />
            </div>
          ) : (
            <ActivityMirror isAfterMirror={isAfterMirror} />
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};
export default ActivityMirrorAnimation;
